import { AgGridReact } from 'ag-grid-react';
import './styles.css';
import { db, storage } from './firebase';

import { getCellStyle, getRowStyle } from './utility';
import { collection, query, getDocs, setDoc, doc, onSnapshot, updateDoc, getDoc, deleteDoc, where } from "firebase/firestore";
import OddsHeader from './OddsHeader';
import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import MyContext from './MyContext';
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Typography } from '@mui/material';
import { ImageCellRenderer, ImageCellRendererScore, ImageCellRendererPaddockOpinion, ImageCellRendererHorseName, ImageCellRendererOpeningOdds } from './utility';

function OddsPaddockSelectionPercentage({ raceNumber, venue, oddsDateValue }) {
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridApiRef = useRef(null);
    const [horseData, setHorseData] = useState([]);
    const navigate = useNavigate();
    // const [finalJudgementRowData, setFinalJudgementRowData] = useState([]);
    const [pageName, setPageName] = useState();
    const [horseStatus, sethorseStatus] = useState();
    const [textInputValue, settextInputValue] = useState();
    const [checked, setChecked] = useState(true);
    const [audioSrc, setAudioSrc] = useState('');
    const [audioSrcFinal, setAudioSrcFinal] = useState('');
    const [success, setSuccess] = useState(0);
    const [showBoxAudio, setShowBoxAudio] = useState(false);
    const [showBox, setShowBox] = useState(false);

    useEffect(() => {
        // setSuccess(0);
        // setShowBox(false);
        // setShowBoxAudio(false);
        // setAudioSrcFinal("");
        // setAudioSrc("");
        if (!oddsDateValue) return navigate("/home");
        setPageName(oddsDateValue + "Race" + raceNumber);
    }, [oddsDateValue, raceNumber]);

    const defaultColDef = useMemo(() => {
        return {
            width: 170,
            resizable: true,
            sortable: true,

        };
    }, []);

    const TwoLineHeader = ({ firstLine, secondLine }) => (
        <div className="two-line-header">
            <div className="first-line">{firstLine}</div>
            <div className="second-line">{secondLine}</div>
        </div>
    );


    const cellCount = 50;
    const generatedCode = [
        {
            maxWidth: 100,
            id: 0,
            headerName: 'Name',
            field: 'horseName',
            hide: !checked,
            rowSpan: (params) => {
                const rowIndex = params.node.rowIndex;
                return rowIndex % 2 === 0 ? 2 : 1;
            },
            // cellStyle: params => {
            //     const backgroundColor = params.data.horseColor;
            //     return { backgroundColor };
            // },
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'horseName', // Apply border class for column 1
                'my-header-class': (params) => params.column.getColId() === 'horseName'
            },
            cellRendererFramework: ImageCellRendererHorseName,

        },
        {
            maxWidth: 60,
            id: 1,
            headerName: '',
            field: 'horseNumber',
            rowSpan: (params) => {
                const rowIndex = params.node.rowIndex;
                return rowIndex % 2 === 0 ? 2 : 1;
            },
            cellStyle: (params) => {
                const backgroundColor = params.data.horseColor;
                return { backgroundColor };
            },
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'horseNumber', // Apply border class for column 1
            },
            cellRendererFramework: ImageCellRenderer,
        },
        {
            // headerName: 'Handicap\npoints',
            field: 'score',
            headerComponentFramework: TwoLineHeader,
            headerComponentParams: { firstLine: 'Handicap', secondLine: 'points' },
            maxWidth: 55,
            hide: !checked,
            rowSpan: (params) => {
                const rowIndex = params.node.rowIndex;
                return rowIndex % 2 === 0 ? 2 : 1;
            },
            cellStyle: params => {
                // const backgroundColor = params.data.horseColor;
                // return { backgroundColor };
            },
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'score', // Apply border class for column 1
                'my-header-class': (params) => params.column.getColId() === 'score',
            },
            cellRendererFramework: ImageCellRendererScore,
        },
        {
            headerName: 'Paddock Opinion',
            field: 'paddockOpinion',
            maxWidth: 120,
            hide: !checked,
            rowSpan: (params) => {
                const rowIndex = params.node.rowIndex;
                return rowIndex % 2 === 0 ? 2 : 1;
            },
            cellStyle: (params) => {
                // const backgroundColor = params.data.paddockColor;
                // return { backgroundColor };
            },
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'paddockOpinion', // Apply border class for column 1
                'my-header-class': (params) => params.column.getColId() === 'paddockOpinion'
            },

            cellRendererFramework: ImageCellRendererPaddockOpinion,
        },
        {
            maxWidth: 30,
            hide: true,//!checked,
            field: 'MO',
            editable: true,
            suppressNavigable: true,
            suppressMovable: true,
        },
        {
            maxWidth: 30,
            hide: true,//!checked,
            field: 'NO',
            suppressNavigable: true,
            suppressMovable: true,
            // cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        },
        {
            maxWidth: 30,
            hide: true,//!checked,
            field: 'OO',
            suppressNavigable: true,
            suppressMovable: true,
            cellRendererFramework: ImageCellRendererOpeningOdds,
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'OO', // Apply border class for column 1
                'my-header-class': (params) => params.column.getColId() === 'OO'
            },
            // cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        },
        {
            maxWidth: 20,
            hide: !checked,
            field: 'pos',
            cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        }];

    for (let i = 1; i <= cellCount; i++) {
        const field = `col${i}`;
        generatedCode.push({
            id: i + 2,
            headerName: '',
            maxWidth: 100,
            field: field,
            suppressNavigable: true,
            suppressMovable: true,
            cellClassRules: {
                'cell-with-parentheses': (params) => {
                    if (typeof params.value === 'string') {
                        const match = params.value.match(/\((-?\d+(\.\d+)?)\)/);
                        if (match) {
                            const number = parseFloat(match[1]);
                            return number < 0;
                        }
                    }
                    return false;
                },
                'cell-without-parentheses': (params) => {
                    if (typeof params.value === 'string') {
                        const match = params.value.match(/\((-?\d+(\.\d+)?)\)/);
                        if (match) {
                            const number = parseFloat(match[1]);
                            return number >= 0;
                        }
                    }
                    return false;
                },
            },

            // cellStyle: params => getCellStyle(params, gridApiRef, field)
        });
    }

    const columnDefsFinalJudgement = [
        {
            headerName: 'Final Judgement',
            field: 'finalJudgement',
            children: [{
                headerName: 'Horse #',
                field: 'horseNumberFJ',
                maxWidth: 30
            },
            {
                headerName: 'Horse Name',
                field: 'horseNameFJ',
                maxWidth: 100
            },
            {
                headerName: 'WIN',
                field: 'win',
                maxWidth: 50
            },
            {
                headerName: 'PLACE',
                field: 'place',
                maxWidth: 50
            }
            ]
        },

    ]

    useEffect(() => {
        setColumnDefs(generatedCode);
        const getHorses = async () => {
            try {
                const q = query(collection(db, oddsDateValue + "horseDetails"));
                const unsubscribefj = onSnapshot(q, (querySnapshotHorse) => {
                    const rowsHorse = [];
                    querySnapshotHorse.forEach((doc) => {
                        rowsHorse.push(doc.data());
                    });
                    setHorseData(rowsHorse);
                });
            } catch (err) {
                console.error(err);
                alert("An error occured while fetching user data");
            }
        }
        getHorses();
    }, [checked]);



    useEffect(() => {

        // const r = query(collection(db, pageName + "finalData"));
        // const unsubscribefj = onSnapshot(r, (querySnapshotFj) => {
        //     const rowsFj = [];
        //     querySnapshotFj.forEach((doc) => {
        //         rowsFj.push(doc.data());
        //     });
        //     if (rowsFj.length > 0) {
        //         rowsFj.sort((a, b) => a.id - b.id);
        //         setFinalJudgementRowData(rowsFj);
        //     }
        //     else {
        //         setFinalJudgementRowData(rowsFj);
        //     }
        // });

        let filteredData = []
        const q = query(collection(db, pageName + "myData"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const rows = [];
            querySnapshot.forEach((doc) => {
                rows.push(doc.data());
            });
            const filteredHorseData = horseData.filter(obj => {
                return obj.raceNumber === raceNumber && (obj.hasOwnProperty('score') && (obj.raceNumber !== null || obj.raceNumber !== ''))
            });
            const filteredData = rows.filter(obj1 =>
                filteredHorseData.some(obj2 =>
                    obj2.horseName === obj1.horseName
                )
            )
            const childrenFilteredData = rows.filter(item => !item.hasOwnProperty("parent"));
            const removedKeys = rows.filter(item => item.hasOwnProperty("parent"));
            const finalData = filteredData.map((data) => (
                {
                    ...data,
                    score: filteredHorseData.find(obj => obj.horseName === data.horseName)?.score || null,
                    horseColor: filteredHorseData.find(obj => obj.horseName === data.horseName)?.horseColor || null,
                    paddockOpinion: filteredHorseData.find(obj => obj.horseName === data.horseName)?.paddockOpinion || null,
                    paddockColor: filteredHorseData.find(obj => obj.horseName === data.horseName)?.paddockColor || null
                }
            ))

            function sortArray(array) {
                array.sort(function (a, b) {
                    if (a.score === null && b.score === null) {
                        return 0; // Both scores are null, maintain the order
                    } else if (a.score === null) {
                        return 1; // Null value should come after non-null values
                    } else if (b.score === null) {
                        return -1; // Null value should come after non-null values
                    }

                    if (isNaN(a.score.replace(/\*/g, '')) && isNaN(b.score.replace(/\*/g, ''))) {
                        return a.score.localeCompare(b.score.replace(/\*/g, '')); // Sort non-numeric strings in ascending order
                    } else if (isNaN(a.score.replace(/\*/g, ''))) {
                        return -1; // Non-numeric string should come before numeric value
                    } else if (isNaN(b.score.replace(/\*/g, ''))) {
                        return 1; // Non-numeric string should come before numeric value
                    } else {
                        return parseFloat(b.score.replace(/\*/g, '')) - parseFloat(a.score.replace(/\*/g, '')); // Sort numeric values in descending order
                    }
                });

                return array;
            }

            function customSort(a, b) {
                const aNum = parseInt(a.horseNumber.slice(1), 10);
                const bNum = parseInt(b.horseNumber.slice(1), 10);
                if (aNum < bNum) {
                    return -1;
                } else if (aNum > bNum) {
                    return 1;
                } else {
                    return 0;
                }
            }

            var sortedData = sortArray(finalData);
            const zeroScoreObjects = sortedData.filter((obj) => parseInt(obj.score) === parseInt(0) || obj.score === null);
            const nonZeroScoreObjects = sortedData.filter((obj) => parseInt(obj.score) !== parseInt(0));
            if (zeroScoreObjects.length === sortedData.length) {
                // All objects have a score equal to zero, sort the entire array by horseNumber
                sortedData.sort(customSort);
            } else {
                // Merge the non-zero score objects after the zero score objects
                const sortedArray = [...zeroScoreObjects, ...nonZeroScoreObjects];
                // Alternatively, you can sort the entire array by horseNumber if you want to keep the zero and non-zero score objects separate.
                // sortedArray.sort((a, b) => a.horseNumber - b.horseNumber);
            }

            removedKeys.forEach(removedKey => {
                const parentIndex = sortedData.findIndex(item => item.horseNumber === removedKey.parent);
                if (parentIndex !== -1) {
                    sortedData.splice(parentIndex + 1, 0, removedKey);
                }
            });
            sortedData.forEach(obj => {
                for (const key in obj) {
                    if (key.startsWith("col")) {
                        const originalValue = parseFloat(obj[key]);
                        const multiplier = parseFloat(obj["OO"]);
                        if (!isNaN(multiplier)) {
                            const m = multiplier + (multiplier * 0.2);
                            const n = originalValue - m;
                            const newValue = -1 * ((n * 100) / m);
                            obj[key] = originalValue + " (" + newValue.toFixed(2).toString() + ")";
                        }
                    }
                }
            });

            sortedData.forEach((obj) => {
                if (obj.hasOwnProperty("parent")) {
                    const parentID = obj.parent;
                    const magicValue = parseFloat(obj.magicValue);
                    if (isNaN(magicValue)) return;
                    const parent = sortedData.find((item) => item.horseNumber === parentID);
                    if (!parent) return;
                    Object.keys(obj).forEach((key) => {
                        if (key.startsWith("col")) {
                            const originalValue = parseFloat(parent[key]);
                            const n = originalValue - magicValue;
                            const newValue = -1 * ((n * 100) / magicValue);
                            obj[key] = originalValue + " (" + newValue.toFixed(2).toString() + ")";
                        }
                    });
                }
            });




















            setRowData(sortedData);
            return () => {
                unsubscribe();
            };

        });
    }, [horseData, pageName])



    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    return (
        <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
            <div style={containerStyle}>
                <OddsHeader
                    venue={venue}
                    pageName={pageName}
                    horseStatus={horseStatus}
                    checked={checked}
                    textInputValue={textInputValue}
                    setChecked={setChecked}
                />
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridApiRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressRowTransform={true}
                        onGridReady={onGridReady}
                        getRowStyle={getRowStyle}
                        rowHeight={25}
                        headerHeight={20}
                    ></AgGridReact>
                </div>
            </div>
        </div>
    )
}

export default OddsPaddockSelectionPercentage;
